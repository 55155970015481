export default {
    // Data
    UPLOAD_DATA: 'UPLOAD_DATA',
    // Story
    GENERATE_STORY: 'GENERATE_STORY',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',//生成进度
    //user
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_OPERATION: 'UPDATE_USER_OPERATION',
    UPDATE_CONVERT_TYPE: 'UPDATE_CONVERT_TYPE',
    UPDATE_LOCALE: 'UPDATE_LOCALE'
}